import React from "react";
import Footer from "./OnlineCodingCompiler/components/Footer";
import Landing from "./OnlineCodingCompiler/components/LandingOLD";
const OnlineCoding = () => {
    return (
        <div>
            <Landing />
          
            
        </div>
    );
};


export default OnlineCoding;