import React, { useEffect, useState } from "react";
import CodeEditorWindow from "./CodeEditorWindow";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { defineTheme } from "../lib/defineTheme";
import useKeyPress from "../hooks/useKeyPress";
import OutputWindow from "./OutputWindow";
import CustomInput from "./CustomInput";
import ThemeDropdown from "./ThemeDropdown";
import { useTestContext } from "../../contextSub/Context";
// import { addTestAnswerMapApi_Code_Com } from "../../../../api/endpoints";
import '../../../../Styles/global.css';
import Select from "react-select";
import { customStyles } from "../constants/customStyles";
import ErrorModal from "../../../../Components/auth/ErrorModal";
import { getSkillType_Languages_API } from "../../../../api/endpoints";


const languageOptions = [
  { id: 71, label: "Select Lang...", value: "" },
  { id: 71, label: "Python", value: "python" },
  { id: 49, label: "C", value: "c" },
  { id: 54, label: "C++", value: "cpp" },
  { id: 62, label: "JAVA", value: "java" }
];

const API_URL = 'https://d36uf68d9l9joj.cloudfront.net';




const Landing = () => {
  const [code, setCode] = useState('');
  const [customInput, setCustomInput] = useState("");
  const [processing, setProcessing] = useState(null);
  const [theme, setTheme] = useState("cobalt");
  const [output, setOutput] = useState("");  // State to store the output

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [skillType, setSkillType] = useState('');

  const {
    testIdCon,
    setCodeWindow,
    setLanguageSelected,
    setCustomInputCom,
    setOutputWindowCom
  } = useTestContext();


  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");
  const [selectLanguage, setSelectLanguage] = useState(languageOptions[0]);

  const handleChangeLanguage = (selectedOption) => {
    setSelectLanguage(selectedOption);
    console.log('Selected language:', selectedOption);
    setLanguageSelected(selectedOption.value);
  };

  useEffect(() => {
    fetchTestName();
  }, [testIdCon]);

  const fetchTestName = async () => {
    try {
      const name = await getSkillType_Languages_API(testIdCon);
      setSkillType(name.skill_type); // Save skill type from the API
      if (name.skill_type !== "All Languages") {
        // Set language automatically if skill_type is not 'All Languages'
        setSelectLanguage(languageOptions.find(option => option.label === name.skill_type));
        setLanguageSelected(name.skill_type.toLowerCase());
        console.log('name.skill_type.toLowerCase(): ', name.skill_type.toLowerCase());
      }
    } catch (error) {
      console.error('Error fetching skill type:', error);
    }
  };

  const handleCloseError = () => {
    setShowError(false);
  };

  useEffect(() => {
    if (enterPress && ctrlPress) {
      console.log("enterPress", enterPress);
      console.log("ctrlPress", ctrlPress);
    }
  }, [ctrlPress, enterPress]);

  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        setCode(data);
        setCodeWindow(data);
        // console.log('setCode: ', data);
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };


  function handleThemeChange(th) {
    const theme = th;
    console.log("theme...", theme);

    if (["light", "vs-dark"].includes(theme.value)) {
      setTheme(theme);
    } else {
      defineTheme(theme.value).then((_) => setTheme(theme));
    }
  }
  useEffect(() => {
    defineTheme("oceanic-next").then((_) =>
      setTheme({ value: "oceanic-next", label: "Oceanic Next" })
    );
  }, []);


  // Define detection functions for different languages
  const languageDetectionMap = {
    python: (code) => {
      const pythonKeywords = [
        "def ", "import ", "print(", "lambda", "yield",
        "async", "await", "class ", "try", "except",
        "if __name__ == '__main__':", "elif", "for ", "while ",
        "from ", "range(", "return", "pass"
      ];
      const notPythonPatterns = [
        /#include/,           // C/C++ header files
        /cout|cin/,           // C++ streams
        /std::/,              // C++ standard namespace
        /using\snamespace/,  // C++ namespace
        /System\.out\.println/,  // Java print statements
        /printf\(/,          // C print function
        /scanf\(/            // C input function
      ];
      
      const hasPythonKeywords = pythonKeywords.some(keyword => code.includes(keyword));
      const hasNotPythonElements = notPythonPatterns.some(pattern => pattern.test(code));
  
      // Debugging output
      if (!hasPythonKeywords) {
        console.log("Missing Python keywords.");
      }
      
      if (hasNotPythonElements) {
        console.log("Found invalid patterns:");
        notPythonPatterns.forEach(pattern => {
          if (pattern.test(code)) {
            console.log(`Pattern matched: ${pattern}`);
          }
        });
      }
      
      return hasPythonKeywords && !hasNotPythonElements;
    },
    cpp: (code) => {
      const cppKeywords = ["#include", "cout", "cin", "std::", "using namespace", "int main(", "::"];
      const notCppPatterns = [
        /public\s+class\s+\w+/,  // Adjusted to specifically target Java "public class"
        /^\s*def\s+\w+\(/,       // Adjusted to Python function definitions
        /^\s*print\(.+/,         // Adjusted to Python's print function
        /System\.out\.println/,  // Java's print statement
        /# Python/,              // Python-specific comment
        /printf\(/,              // C print function
        /scanf\(/,               // C input function
        /#include\s*<stdio.h>/   // C header
      ];
      const hasCppKeywords = cppKeywords.some(keyword => code.includes(keyword));
      const hasNotCppElements = notCppPatterns.some(pattern => pattern.test(code));

      // Debugging output
      if (!hasCppKeywords) {
        console.log("Missing C++ keywords.");
      }

      if (hasNotCppElements) {
        console.log("Found invalid patterns:");
        notCppPatterns.forEach(pattern => {
          if (pattern.test(code)) {
            console.log(`Pattern matched: ${pattern}`);
          }
        });
      }
      return hasCppKeywords && !hasNotCppElements;
    },
    java: (code) => {
      const javaKeywords = ["public class", "public static void main", "System.out.println", "new ", "extends ", "implements "];
      const notJavaPatterns = [/#include/, /cout/, /cin/, /printf\(/, /def /];
      const hasJavaKeywords = javaKeywords.some(keyword => code.includes(keyword));
      const hasNotJavaElements = notJavaPatterns.some(pattern => pattern.test(code));
      return hasJavaKeywords && !hasNotJavaElements;
    },
    c: (code) => {
      const cKeywords = ["#include", "printf(", "scanf(", "int main(", "return 0;"];
      const notCElements = [
        /public\s+class\s+\w+/, // Java class declarations
        /^\s*def\s+\w+\(/, // Python function definitions
        /print\(/, // Python's print function
        /System\.out\.println/, // Java print statements
        /cout\s*<</, // C++ output stream
        /cin\s*>>/, // C++ input stream
        /std::/, // C++ namespace
        /# Python/, // Python-specific comment
        /#include\s*<iostream>/ // C++ header files
      ];
    
      const hasCKeywords = cKeywords.some(keyword => code.includes(keyword));
      const hasNotCElements = notCElements.some(pattern => pattern.test(code));
    
      console.log("C Keywords present:", hasCKeywords);
      console.log("Non-C Elements present:", hasNotCElements);
    
      return hasCKeywords && !hasNotCElements;
    }
    
  };


  const handleSubmit = () => {
    setProcessing(true);

    const dataToSubmit = {
      code: code,
      p_type: selectLanguage.value,
      inputs: customInput,
    };
    console.log('Data To Submit: ', dataToSubmit);

    // Validate if the code matches the selected language
    const validateLanguage = languageDetectionMap[selectLanguage.value];

    if (!validateLanguage || !validateLanguage(code)) {
      setErrorMessage(`The provided code does not match the selected language: ${selectLanguage.label}.`);
      setShowError(true);
      setProcessing(false);
      return;
    }

    // Detect if the code requires input based on the selected language
    const requiresInput =
      (selectLanguage.value === 'python' && code.includes('input(')) ||
      (selectLanguage.value === 'c' && code.includes('scanf(')) ||
      (selectLanguage.value === 'cpp' && code.includes('cin')) ||
      (selectLanguage.value === 'java' && code.includes('Scanner'));

    if (requiresInput && !customInput.trim()) {
      setErrorMessage("The code requires input, but no input was provided. Please provide the necessary input.");
      setShowError(true);
      setProcessing(false);
      return;
    }

    // Step 1: Detect input functions for each language
  let expectedInputs = 0;

  if (selectLanguage.value === 'python') {
    expectedInputs = (code.match(/input\s*\(/g) || []).length; // Python: input()
  } else if (selectLanguage.value === 'c') {
    expectedInputs = (code.match(/scanf\s*\(/g) || []).length; // C: scanf
  } else if (selectLanguage.value === 'cpp') {
    expectedInputs = (code.match(/cin/g) || []).length;    // C++: cin
  } else if (selectLanguage.value === 'java') {
    expectedInputs = (code.match(/scanner\s*\.\s*next(Line)?\s*\(\s*\)/g) || []).length; // Java: Scanner input (nextLine() or next())
  }

  const providedInputs = (customInput.trim() === '' ? 0 : customInput.split(/\r?\n/).length); // Count number of lines in provided input

  // Step 2: Check if validation is necessary
  if (expectedInputs > 0 && expectedInputs !== providedInputs) {
    const errorMessage = `You have ${expectedInputs} inputs in the code, but you provided ${providedInputs}. Please provide the correct number of inputs.`;
    console.error(errorMessage);  // Display the error message
    setErrorMessage(errorMessage);
    setShowError(true);
    setProcessing(false);
    return;
  }

  // If no inputs are expected or the input matches the required amount, proceed
  console.log("Inputs are valid or not required, proceeding to execute the code.");



    // First API call to start the code execution
    axios.post(`${API_URL}/program-compiler/`, dataToSubmit)
      .then(response => {
        console.log("Full response:", response.data);

        if (response.data && response.data.status === "SUCCESS") {
          setOutput(response.data.result);
          setOutputWindowCom(response.data.result);
          console.log('Compiled Successfully!');
          setProcessing(false);
          setCustomInput('');

        }
      })
      .catch(error => {
        console.error("Failed to submit:", error);
        setErrorMessage("Failed to submit");
        setShowError(true);
        setProcessing(false);
      });
  };



  const handleSetCustomInput = (input) => {
    console.log("Input handle: ", input);
    setCustomInput(input);
    setCustomInputCom(input);
  };




  return (
    <>


      <ToastContainer />


      <div className="flex-row">
        {skillType === "All Languages" ? (
          <div className="dropdown-container">
            <Select
              options={languageOptions}
              value={selectLanguage}
              onChange={handleChangeLanguage}
              styles={customStyles}
            />
          </div>
        ) : (
          <div className="dropdown-container">
            <span>Language: {skillType}</span> {/* Display the skill type */}
          </div>
        )}
        <div className="dropdown-container">
          <ThemeDropdown
            handleThemeChange={handleThemeChange}
            theme={theme}
          />
        </div>
        <div className="dropdown-container">
          <button
            onClick={() => { handleSubmit(); }}
            disabled={processing || !code} // Disable if processing is true or code is empty
            className="compile-button"
          >
            {processing ? "Processing..." : "Run Code"}
          </button>
        </div>
      </div>


      <div className="mn-con">
        <div className="code-editor-container">
          <CodeEditorWindow
            code={code}
            onChange={onChange}
            language={selectLanguage}
            theme={theme.value}
          />
        </div>


      </div>

      <div className="mn-con" style={{ marginTop: '10px' }}>
        <div style={{ display: 'flex', marginTop: '10px' }}>
          <div className="custom-input-container">
            <CustomInput
              customInput={customInput}
              setCustomInput={handleSetCustomInput}

            />
          </div>

          <div className="output-details-n" style={{ marginLeft: '10px' }}>
            <OutputWindow output={output} />

          </div>
        </div>
      </div>

      <ErrorModal show={showError} handleClose={handleCloseError} errorMessage={errorMessage} />

    </>
  );
};
export default Landing;