import React, { useState, useEffect } from 'react';
import { Container, Card, Col, Row, Form, Button } from 'react-bootstrap';
import {
    addLogin_Profile_API,
    addLoginApi,
    getcollegeApi,
    addTrainer_username_API
} from '../../api/endpoints';
import ErrorModal from './ErrorModal';
import Footer from '../../Footer/Footer';
import Select from 'react-select';
import LoginTable from './LoginTable';
import Next from '../../assets/Images/nextarrow.png';
import back from '../../assets/Images/backarrow.png';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: '#39444e',
        color: '#fff', // Text color
        borderColor: state.isFocused ? '' : '#ffff', // Border color on focus
        boxShadow: 'none', // Remove box shadow
        '&:hover': {
            borderColor: state.isFocused ? '#ffff' : '#ffff' // Border color on hover
        },
        '&.css-1a1jibm-control': {
            // Additional styles for the specific class
        },
        '@media (max-width: 768px)': { // Adjust for mobile devices
            fontSize: '12px', // Smaller font size

            width: '78%'
        }
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#ffff', // Text color for selected value
        '@media (max-width: 768px)': { // Adjust for mobile devices
            fontSize: '12px' // Smaller font size
        }
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#39444e' : state.isFocused ? '#39444e' : '#39444e',
        color: '#ffff', // Text color
        '&:hover': {
            backgroundColor: '#39444e', // Background color on hover
            color: '#ffff' // Text color on hover
        },
        '@media (max-width: 768px)': { // Adjust for mobile devices
            fontSize: '12px',// Smaller font size
            width: '78%'
        }
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#39444e',
        '@media (max-width: 768px)': { // Adjust for mobile devices
            fontSize: '12px' // Smaller font size
        }
    })
};
function LoginCreate() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [rememberMe, setRememberMe] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isRegisterMode, setIsRegisterMode] = useState(true);
    const [role, setRole] = useState('');
    const [instituteName, setInstituteName] = useState([]); // Step 1: Add state variable for institute_name
    const [selectedInstitute, setSelectedInstitute] = useState([]); // Corrected variable name
    const [showAddstudent, setshowAddstudent] = useState(false); // State variable to track visibility
    const handlePreviousButtonClick = () => {
        setshowAddstudent(false); // Show the table
    };
    const handleNextButtonClick = () => {
        setshowAddstudent(true); // Show the Add Student form
    };
    useEffect(() => {
        getcollegeApi()
            .then(data => {
                setInstituteName(data.map(item => ({ value: item.id, label: item.college })));
            })
            .catch(error => console.error('Error fetching data:', error));

    }, []);


    async function handleAddTrainer(userName) {
        try {
            const response = await addTrainer_username_API(userName);
            console.log('Trainer added successfully:', response);
        } catch (error) {
            console.error('Failed to add trainer:', error);
        }
    }


    const handleRegister = (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        const collegeId = selectedInstitute ? selectedInstitute.value : null;

        if (username.trim().length !== 0 && password.trim().length !== 0 && role.trim().length !== 0) {
            // Get current date and time for dtm_created
            const dtmCreated = new Date();

            // Initialize dtm_trainer as null
            let dtmTrainer = null;

            if (role === 'Trainer') {
                // Get dtm_trainer date and time by adding 5 minutes
                dtmTrainer = new Date(dtmCreated);
                dtmTrainer.setMinutes(dtmTrainer.getMinutes() + 5);

            }
            const requestData = {
                email: email,
                user_name: username,
                password: password,
                role: role,
                college_id: collegeId, // Step 3: Include institute_name in requestData
                dtm_created: dtmCreated.toISOString(),
                dtm_trainer: dtmTrainer ? dtmTrainer.toISOString() : null,
            };
            console.log(requestData)

            addLoginApi(requestData)
                .then(result => {
                    setErrorMessage('Register Successfully');
                    setShowError(true);

                    // Check if login is added successfully and the role is 'Trainer'
                    if (result && role === 'Trainer') {
                        handleAddTrainer(username); // Pass the username to the function
                    }

                    // Clear form fields after successful registration
                    setUsername('');
                    setPassword('');
                    setRole('');
                    setEmail('');
                    setSelectedInstitute(''); // Reset institute_name state after successful registration
                })
                .catch(error => {
                    setErrorMessage('Register is not Added');
                    setShowError(true);
                    setUsername('');
                    setPassword('');
                    setRole('');
                    setSelectedInstitute('');
                    handleNextButtonClick();
                });
        }
    };

    const handleCloseError = () => {
        setShowError(false);
    };

    const handleLoginClick = () => {
        setIsRegisterMode(false); // Set register mode to false to show login screen
    };

    return (
        <div>
            {!showAddstudent ? (
        <div className='form-ques'>
            <Row>
                <form onSubmit={handleRegister} className="form-ques">
                    <Row md={12}>
                        <Col>
                            <div controlId="username" className='add-profile'>
                                <label className="label5-ques">User Name</label> <p></p>
                                <input type="text" placeholder="Enter Username" className='input-ques' value={username} onChange={(e) => setUsername(e.target.value)} autocomplete="off" />
                            </div>
                        </Col>
                        <Col>
                            <div controlId="userType" className='add-profile'>
                                <label className="label5-ques">User Type</label> <p></p>
                                <select className='input-ques' value={role} onChange={(e) => setRole(e.target.value)}>
                                    <option value="">Select role</option>
                                    <option value="Placement admin">Placement admin</option>
                                    <option value="Training admin">Training admin</option>
                                    <option value="Super admin">Super admin</option>
                                    <option value="Student">Student</option>
                                    <option value="Trainer">Trainer</option>
                                </select>
                            </div>
                        </Col>
                    </Row>

                    <p></p>
                    <Row md={12}>
                        <Col>
                            <div controlId="email" className='add-profile'>
                                <label className="label5-ques">Email</label> <p></p>
                                <input className='input-ques' type="email" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} autocomplete="off" />
                            </div>
                        </Col>
                        <Col>
                            <div controlId="instituteName" >
                                <label className="label5-ques">Institute Name</label> <p></p>
                                <div >
                                    <Select
                                        options={instituteName}
                                        value={selectedInstitute}
                                        onChange={setSelectedInstitute}
                                        placeholder="Select College"

                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row> <p></p>
                    <Row md={12}>
                        <Col>
                            <div controlId="password" className='add-profile'>
                                <label className="label5-ques">Password</label> <p></p>
                                <input className='input-ques' type="password" placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} autocomplete="off" />
                            </div>
                        </Col>
                        <Col>
                        </Col>
                    </Row>


                    <p style={{ height: "40px" }}></p>
                    <Row className="justify-content-center">
                    <Row>
                                        <Col>
                                            <div className="button-container-lms-update">
                                                <button

                                                    className="button-ques-save btn btn-secondary back-button-lms"
                                                    style={{
                                                        width: "100px",
                                                        color: 'black',
                                                        height: '50px',
                                                        backgroundColor: '#F1A128',
                                                        cursor: 'not-allowed'
                                                    }}
                                                    disabled
                                                ><img src={back} className='nextarrow' ></img>
                                                    <span className="button-text">Back</span>
                                                </button>

                                                <button type="submit" style={{ width: "102px" }} className="button-ques-save-data">
                            Signup
                        </button>
                                                <button

                                                    className="button-ques-save"
                                                    style={{
                                                        width: "100px",
                                                        color: 'black',
                                                        height: '50px',
                                                        backgroundColor: '#F1A128',
                                                       
                                                    }} onClick={handleNextButtonClick}
                                                   >
                                                    <span className="button-text">Next</span>
                                                    <img src={Next} className='nextarrow'></img>
                                                </button>

                                            </div>
                                        </Col>
                                    </Row>
                       

                    </Row>
                    <p></p>
                </form>
                <br />
                <ErrorModal show={showError} handleClose={handleCloseError} errorMessage={errorMessage} />
            </Row><p style={{ height: "50px" }}></p>
            {/*  <Footer></Footer>*/}
        </div>

) : (
    <div >
        <button onClick={handlePreviousButtonClick} className='button-ques-save'><img src={back} className='nextarrow' ></img>
            <span>Back</span></button>
        <LoginTable />
    </div>
)}
        </div>
    );
}

export default LoginCreate;