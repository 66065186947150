import React, { useState } from "react";
import Editor from "@monaco-editor/react";

const CodeEditorWindow = ({ onChange, language, code, theme }) => {
    const [value, setValue] = useState(code || "");

    const handleEditorChange = (value) => {
        setValue(value);
        onChange("code", value);
    };

    const preventCopyPaste = (editor) => {
        editor.onKeyDown((event) => {
            const { code } = event;
            if (code === "KeyC" && (event.ctrlKey || event.metaKey)) {
                event.preventDefault(); // Prevent Ctrl+C (copy)
            }
            if (code === "KeyX" && (event.ctrlKey || event.metaKey)) {
                event.preventDefault(); // Prevent Ctrl+X (cut)
            }
            if (code === "KeyA" && (event.ctrlKey || event.metaKey)) {
                event.preventDefault(); // Prevent Ctrl+X (cut)
            }
            if (code === "KeyV" && (event.ctrlKey || event.metaKey)) {
                event.preventDefault(); // Prevent Ctrl+V (paste)
            }
        });

        editor.onDidPaste(() => {
            // Disable paste with the mouse right-click
            editor.trigger("keyboard", "undo", null);
        });
    };

    const handleEditorMount = (editor) => {
        preventCopyPaste(editor);

        // Disable right-click context menu
        editor.onContextMenu((e) => {
            e.event.preventDefault(); // Use the event object from Monaco's custom event
        });
    };

    return (
        <div >
            <Editor
                height="50vh"
                width={`100%`}
                language={language || "cpp"}
                value={value}
                theme={theme}
                defaultValue=""
                onChange={handleEditorChange}
                onMount={handleEditorMount}
            />
        </div>
    );
};
export default CodeEditorWindow;