

const AddNonDBCandidates = () => {
    return (
        <div>
            Non-DB Candidates
        </div>
    );
};

export default AddNonDBCandidates;




















